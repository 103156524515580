import { EmptyState, Link, Select, Table } from '@backstage/core-components';
import {
  EntityPeekAheadPopover,
  EntityRefLink,
  useEntity,
} from '@backstage/plugin-catalog-react';
import {
  CompoundEntityRef,
  Entity,
  parseEntityRef,
} from '@backstage/catalog-model';
import React, { useState } from 'react';
import {
  techInsightsApiRef,
  BooleanCheck,
} from '@backstage-community/plugin-tech-insights';
import { Check } from '@backstage-community/plugin-tech-insights-common/client';
import { CheckResult } from '@backstage-community/plugin-tech-insights-common';
import { useApi } from '@backstage/core-plugin-api';
import useAsync from 'react-use/lib/useAsync';
import {
  backendChecks,
  metadataChecks,
  templateChecks,
  branchChecks,
  complianceChecks,
  checkHeaders,
} from '@internal/backstage-plugin-tech-insights-fact-checkers-common';
import HelpOutline from '@material-ui/icons/HelpOutline';

function getProjects(entity: Entity): CompoundEntityRef[] {
  return (entity.relations || [])
    .filter(i => i.type === 'ownerOf')
    .map(i => parseEntityRef(i.targetRef))
    .filter(i => i.kind.toLowerCase() === 'component');
}

type TechInsightsTableRow = Record<string, CheckResult | string>;

/**
 * Translates check results to a number for sorting.
 * @param {CheckResult} value result returned by tech insights API.
 * @returns {number} 1 for successful checks, 0 for failed checks and -1 for checks with incomplete facts.
 */
function sortValue(value: string | CheckResult): number {
  const checkResult = value as CheckResult;
  if (checkResult && checkResult.facts) {
    const facts = Object.values(checkResult.facts).map(i => i.value);
    if (facts.every(i => Object.keys(i).length > 0)) {
      return checkResult.result ? 1 : 0;
    }
  }
  return -1;
}

function checkRender(
  field: string,
): (row: TechInsightsTableRow) => React.ReactNode {
  return (row: TechInsightsTableRow) => {
    const checkResult: CheckResult = row[field] as CheckResult;
    if (checkResult && checkResult.facts) {
      const facts = Object.values(checkResult.facts).map(i => i.value);
      if (facts.every(i => Object.keys(i).length > 0)) {
        const link = checkResult.check.metadata?.documentationLink;
        const checkComponent = <BooleanCheck checkResult={checkResult} />;
        return link ? <Link to={link}>{checkComponent}</Link> : checkComponent;
      }
    }
    return <HelpOutline color="disabled" />;
  };
}

const allChecks: Record<string, string[]> = {
  metadataChecks,
  templateChecks,
  backendChecks,
  branchChecks,
  complianceChecks,
};

export const TechInsightsTable = () => {
  const { entity } = useEntity();

  const projects = getProjects(entity) || [];

  const techInsightsApi = useApi(techInsightsApiRef);
  const [checks, setChecks] = useState<string[]>([
    'metadataChecks',
    'templateChecks',
    'backendChecks',
  ]);
  const { value, loading } = useAsync(async () => {
    const repositories: Record<string, TechInsightsTableRow> = {};
    for (const check of Object.keys(allChecks).flatMap(
      (i: string) => allChecks[i],
    )) {
      // Forcing type, to select only specific check IDs. Rest of check info is for UI.
      const bulkCheckResponse = await techInsightsApi.runBulkChecks(projects, [
        { id: check } as Check,
      ]);
      for (const entityCheckResults of bulkCheckResponse) {
        if (!repositories[entityCheckResults.entity]) {
          repositories[entityCheckResults.entity] = {
            repository: entityCheckResults.entity,
          };
        }
        const checkResultMap: Record<string, CheckResult> = {};
        for (const checkResult of entityCheckResults.results) {
          checkResultMap[checkResult.check.id] = checkResult;
        }
        repositories[entityCheckResults.entity] = {
          ...repositories[entityCheckResults.entity],
          ...checkResultMap,
        };
      }
    }
    return Object.values(repositories);
  });

  const columns = checks
    .flatMap(i => allChecks[i])
    .map((check: string) => ({
      title: checkHeaders[check] || check,
      field: check,
      highlight: false,
      customSort: (a: TechInsightsTableRow, b: TechInsightsTableRow) => {
        const first: string | CheckResult = a[check];
        const second: string | CheckResult = b[check];
        if (typeof first === 'string' && typeof second === 'string') {
          return first.localeCompare(second);
        }
        return sortValue(first) - sortValue(second);
      },
      render: checkRender(check),
    }));

  if (value && value.length === 0) {
    return (
      <EmptyState
        missing="info"
        title="No information to display"
        description="Your team has either no components or no checks are available."
      />
    );
  }

  const SELECT_ITEMS = [
    {
      label: 'Metadata Checks',
      value: 'metadataChecks',
    },
    {
      label: 'Template Checks',
      value: 'templateChecks',
    },
    {
      label: 'Backend Checks',
      value: 'backendChecks',
    },
    {
      label: 'Branch Checks',
      value: 'branchChecks',
    },
    {
      label: 'Compliance Checks',
      value: 'complianceChecks',
    },
  ];

  return (
    <>
      <Select
        items={SELECT_ITEMS}
        label="Selected Check Groups"
        onChange={i => setChecks(i as string[])} // Multiple section is always array.
        multiple
        selected={checks}
      />
      <Table<TechInsightsTableRow>
        options={{ paging: false }}
        data={value || []}
        isLoading={loading || !value}
        columns={[
          {
            title: 'Components',
            field: 'repository',
            highlight: true,
            render: (row: TechInsightsTableRow) => (
              <EntityPeekAheadPopover entityRef={row.repository as string}>
                <EntityRefLink entityRef={row.repository as string} />
              </EntityPeekAheadPopover>
            ),
          },
          ...columns,
        ]}
        title="Tech Insights"
        subtitle="For components owned by this team."
      />
    </>
  );
};
