import { Operator } from 'json-rules-engine';
import {
  type Dependencies,
  type Dependency,
  type Java,
  JSON_RULE_ENGINE_CHECK_TYPE,
  type TechInsightJsonRuleCheck,
} from '../../types';

const EXCLUDED_DEPENDENCIES = [
  'spring-boot-starter-is24-sso', // this is one of Scout dependencies, that does not follow SpringBoot versioning
];

export const javaVersionCheck = (
  factRetrieverIds: string[],
): TechInsightJsonRuleCheck => ({
  id: 'javaVersionCheck',
  type: JSON_RULE_ENGINE_CHECK_TYPE,
  name: 'Recommended Java version is used',
  description:
    'Checks if a Java application uses our recommended version, which is at least Java 17 for Spring projects and Java 8 for the rest.',
  factIds: factRetrieverIds,
  rule: {
    conditions: {
      any: [
        {
          all: [
            {
              fact: 'java',
              operator: 'javaVersionGreaterEqualThan',
              value: 8,
            },
            {
              fact: 'dependencies',
              operator: 'usesSpring',
              value: false,
            },
          ],
        },
        {
          all: [
            {
              fact: 'java',
              operator: 'javaVersionGreaterEqualThan',
              value: 17,
            },
            {
              fact: 'dependencies',
              operator: 'usesSpring',
              value: true,
            },
          ],
        },
      ],
    },
  },
});

export const springFrameworksCheck = (
  factRetrieverIds: string[],
): TechInsightJsonRuleCheck => ({
  id: 'springFrameworksCheck',
  type: JSON_RULE_ENGINE_CHECK_TYPE,
  name: 'Recommended Spring frameworks are used',
  description:
    'Checks if the Spring frameworks have been used and are on long term support.',
  factIds: factRetrieverIds,
  rule: {
    conditions: {
      any: [
        {
          fact: 'dependencies',
          operator: 'springBootVersionGreaterThan',
          value: '3.2',
        },
      ],
    },
  },
});

function extractTargetVersions(source: Java): number[] {
  const versions: number[] = [];
  for (const value of Object.values(source)) {
    let target;
    switch (typeof value) {
      case 'object':
        versions.push(...extractTargetVersions(value));
        break;
      case 'number':
        versions.push(value);
        break;
      case 'string':
        target = parseInt(value, 10);
        if (!isNaN(target)) {
          versions.push(target);
        }
        break;
      default:
        throw new Error('Unexpected type');
    }
  }
  return versions;
}

const javaVersionGreaterEqualThan = new Operator(
  'javaVersionGreaterEqualThan',
  (a: Java, b: number) => {
    const versions: number[] = extractTargetVersions(a);
    const lowest = versions.sort((x, y) => x - y)[0];
    return lowest >= b;
  },
);

const usesSpring = new Operator('usesSpring', (a: Dependencies, b: boolean) => {
  const springDependencies = Object.keys(a)
    .filter(i => i.match(/.*spring-boot.*/))
    .flatMap(i => a[i]);
  return springDependencies.length > 0 === b;
});

const springBootVersionGreaterThan = new Operator(
  'springBootVersionGreaterThan',
  (a: Dependencies, b: string) => {
    const versions: Dependency[] = Object.keys(a)
      .filter(
        i =>
          (i.startsWith('spring-boot') || i.startsWith('is24-spring-boot')) &&
          !EXCLUDED_DEPENDENCIES.includes(i),
      )
      .flatMap(i => a[i]);
    return versions.length === 0 || versions.every(i => (i.version ?? '') > b);
  },
);

export const backendOperators = [
  javaVersionGreaterEqualThan,
  springBootVersionGreaterThan,
  usesSpring,
];

export const backendCheckInstances = (
  factRetrieverIds: string[],
): TechInsightJsonRuleCheck[] =>
  [javaVersionCheck, springFrameworksCheck].map(i => i(factRetrieverIds));

export const backendChecks = backendCheckInstances([]).map(i => i.id);
