import { Operator } from 'json-rules-engine';
import {
  type Branches,
  JSON_RULE_ENGINE_CHECK_TYPE,
  TechInsightJsonRuleCheck,
} from '../../types';

export const defaultBranchBrokenCheck = (
  factRetrieverIds: string[],
): TechInsightJsonRuleCheck => ({
  id: 'defaultBranchBrokenCheck',
  type: JSON_RULE_ENGINE_CHECK_TYPE,
  name: 'Default Branch Checks are ok',
  description: 'Checks if the default branch checks have all successfully run.',
  factIds: factRetrieverIds,
  rule: {
    conditions: {
      any: [
        {
          all: [
            {
              fact: 'branches',
              operator: 'matchesBranches',
              value: {
                defaultBranch: 'success',
              },
            },
          ],
        },
      ],
    },
  },
});

export const tooManyBranchesCheck = (
  factRetrieverIds: string[],
): TechInsightJsonRuleCheck => ({
  id: 'tooManyBranchesCheck',
  type: JSON_RULE_ENGINE_CHECK_TYPE,
  name: 'Trunk Based Development',
  description:
    'Checks if there are no more than 5 active branches, an indicator for good trunk based development.',
  factIds: factRetrieverIds,
  rule: {
    conditions: {
      any: [
        {
          all: [
            {
              fact: 'branches',
              operator: 'lessThanBranches',
              value: {
                count: 5,
              },
            },
          ],
        },
      ],
    },
  },
});

export const tooManyPRsCheck = (
  factRetrieverIds: string[],
): TechInsightJsonRuleCheck => ({
  id: 'tooManyPRsCheck',
  type: JSON_RULE_ENGINE_CHECK_TYPE,
  name: 'Number of open PRs',
  description:
    'Checks if there are no more than 3 open PRs, indicating need to review and merge to main.',
  factIds: factRetrieverIds,
  rule: {
    conditions: {
      any: [
        {
          all: [
            {
              fact: 'branches',
              operator: 'lessThanBranches',
              value: {
                prBranches: 3,
              },
            },
          ],
        },
      ],
    },
  },
});

export const dependabotCheck = (
  factRetrieverIds: string[],
): TechInsightJsonRuleCheck => ({
  id: 'dependabotCheck',
  type: JSON_RULE_ENGINE_CHECK_TYPE,
  name: 'Dependabot PRs',
  description: 'Checks if there are no pending Dependabot PRs.',
  factIds: factRetrieverIds,
  rule: {
    conditions: {
      any: [
        {
          all: [
            {
              fact: 'branches',
              operator: 'lessThanBranches',
              value: {
                dependabotBranches: 0,
              },
            },
          ],
        },
      ],
    },
  },
});

export const failedBranchesCheck = (
  factRetrieverIds: string[],
): TechInsightJsonRuleCheck => ({
  id: 'failedBranchesCheck',
  type: JSON_RULE_ENGINE_CHECK_TYPE,
  name: 'Number of Failed Branches',
  description: 'Checks if there are no branches failing Github branch checks.',
  factIds: factRetrieverIds,
  rule: {
    conditions: {
      any: [
        {
          all: [
            {
              fact: 'branches',
              operator: 'lessThanBranches',
              value: {
                failedBranches: 0,
              },
            },
          ],
        },
      ],
    },
  },
});

export const staleBranchesCheck = (
  factRetrieverIds: string[],
): TechInsightJsonRuleCheck => ({
  id: 'staleBranchesCheck',
  type: JSON_RULE_ENGINE_CHECK_TYPE,
  name: 'Number of Stale Branches',
  description: 'Checks if there are no stale branches older than 30 days.',
  factIds: factRetrieverIds,
  rule: {
    conditions: {
      any: [
        {
          all: [
            {
              fact: 'branches',
              operator: 'lessThanBranches',
              value: {
                staleBranches: 0,
              },
            },
          ],
        },
      ],
    },
  },
});

const matchesBranches = new Operator(
  'matchesBranches',
  (a: Record<string, any>, b: Record<string, any>) => {
    return Object.keys(b).filter(key => a[key] === b[key]).length > 0;
  },
);

const lessThanBranches = new Operator(
  'lessThanBranches',
  (a: Record<string, any>, b: Record<string, any>) => {
    return Object.keys(b).filter(key => a[key] > b[key]).length === 0;
  },
);

export const branchOperators = [matchesBranches, lessThanBranches];

export const branchCheckInstances = (
  factRetrieverIds: string[],
): TechInsightJsonRuleCheck[] =>
  [
    defaultBranchBrokenCheck,
    tooManyBranchesCheck,
    tooManyPRsCheck,
    dependabotCheck,
    failedBranchesCheck,
    staleBranchesCheck,
  ].map(i => i(factRetrieverIds));

export const branchChecks = branchCheckInstances([]).map(i => i.id);
