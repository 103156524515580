import { Operator } from 'json-rules-engine';
import {
  type Dependencies,
  type Metadata,
  type Tenpureto,
  JSON_RULE_ENGINE_CHECK_TYPE,
  type TechInsightJsonRuleCheck,
} from '../../types';

export const templatePresentCheck = (
  factRetrieverIds: string[],
): TechInsightJsonRuleCheck => ({
  id: 'templatePresentCheck',
  type: JSON_RULE_ENGINE_CHECK_TYPE,
  name: 'Template is used',
  description: 'Checks if the project uses one of Scout recommended templates.',
  factIds: factRetrieverIds,
  rule: {
    conditions: {
      any: [
        {
          fact: 'tenpureto',
          operator: 'comparePresentTenpureto',
          value: true,
        },
      ],
    },
  },
});

export const templateTooOldCheck = (
  factRetrieverIds: string[],
): TechInsightJsonRuleCheck => ({
  id: 'templateTooOldCheck',
  type: JSON_RULE_ENGINE_CHECK_TYPE,
  name: 'Template used is up-to-date',
  description:
    'Checks if the project uses a recent version of the template, not older than 90 days.',
  factIds: factRetrieverIds,
  rule: {
    conditions: {
      any: [
        {
          all: [
            {
              fact: 'tenpureto',
              operator: 'comparePresentTenpureto',
              value: true,
            },
            {
              fact: 'tenpureto',
              operator: 'diffDaysTenpuretoLowerThan',
              value: 90,
            },
          ],
        },
      ],
    },
  },
});

export const scoutStandardsCheck = (
  factRetrieverIds: string[],
): TechInsightJsonRuleCheck => ({
  id: 'scoutStandardsCheck',
  type: JSON_RULE_ENGINE_CHECK_TYPE,
  name: 'Scout dependency standards',
  description:
    'Checks if the project uses provided libraries and dependencies from Scout24 when they apply.',
  factIds: factRetrieverIds,
  rule: {
    conditions: {
      any: [
        {
          fact: 'dependencies',
          operator: 'usageOfSpringBootStandardsIsConform',
          value: true,
        },
      ],
    },
  },
});

const usageOfSpringBootStandardsIsConform = new Operator(
  'usageOfSpringBootStandardsIsConform',
  (a: Dependencies) => {
    const usesSpringBoot = Object.keys(a).some(i => i.match(/.*spring-boot.*/));
    return usesSpringBoot
      ? Object.keys(a).some(i => i.startsWith('is24-spring-boot-standards'))
      : true;
  },
);

const comparePresentTenpureto = new Operator(
  'comparePresentTenpureto',
  (a: Metadata, b: boolean) => a.present === b,
);

const DAY_IN_MILLIS = 1000 * 60 * 60 * 24;

const diffDaysTenpuretoLowerThan = new Operator(
  'diffDaysTenpuretoLowerThan',
  (a: Tenpureto, b: number) => {
    const templateDate = new Date(a.lastUpdate);
    return (new Date().getTime() - templateDate.getTime()) / DAY_IN_MILLIS <= b;
  },
);

export const templateOperators = [
  comparePresentTenpureto,
  diffDaysTenpuretoLowerThan,
  usageOfSpringBootStandardsIsConform,
];

export const templateCheckInstances = (
  factRetrieverIds: string[],
): TechInsightJsonRuleCheck[] =>
  [templatePresentCheck, templateTooOldCheck, scoutStandardsCheck].map(i =>
    i(factRetrieverIds),
  );

export const templateChecks = templateCheckInstances([]).map(i => i.id);
